import React from 'react'

import PageWrapper from 'components/misc/PageWrapper'

import styles from './app.module.scss'

type Props = {}

const AppPage = ({}: Props) => (
  <PageWrapper title="Archidekt App" description="Questions and answers on an Archidekt native phone app">
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Archidekt App</h1>

        <h3>Does Archidekt have a native app for android or ios?</h3>

        <p>
          Short answer, <b>no</b>; longer answer, <i>kinda</i>.
        </p>

        <p>
          While Archidekt does not have a native application that is installable from the App Store/ Play Store, you can
          install Archidekt to your homescreen via your browser to mimic that experience. This is done by using Chrome
          on Android, and Safari on iOS, by selecting the "Add to Home Screen" option in the browser menu.
        </p>

        <p>
          If you're looking for more detailed instructions, here's a{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://web.dev/learn/pwa/installation">
            link
          </a>{' '}
          to an extrenal site that goes through the steps in more details. Alternatively if you need help, or have any
          questions feel free to reach out to us on our{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://discord.gg/GyAAANU8uJ">
            Discord
          </a>
          , or other social media.
        </p>

        <h3>Will Archidekt ever build a native application?</h3>

        <p>
          Currently we have no plans to do so. We run a very lean development team, and spending time building a native
          application would take time away from the web development. We try to make Archidekt as mobile friendly as
          possible when building the site, and we feel like our mobile experience provides a good experiance for our
          mobile users as is.
        </p>

        <p>
          If you have any suggestions, or feedback on how we can improve the mobile experiance, please feel free to
          reach out to us on our{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://discord.gg/GyAAANU8uJ">
            Discord server
          </a>
          , or other social media.
        </p>

        <h3>Are we missing other information?</h3>

        <p>
          If we're missing any information, reach out to us on our{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://discord.gg/GyAAANU8uJ">
            Discord
          </a>
          , social media, or the forums.
        </p>
      </div>
    </div>
  </PageWrapper>
)

export default AppPage
